<template>
  <div class="student-exam-details">
    <el-form class="zwx-form" key="examDetailForm" ref="examDetailForm" :model="examDetailForm" label-position="right" @submit.native.prevent>
      <div>
        <el-form-item label="培训机构：" label-width="70px">
          <el-input class="zwx-input" v-model.trim="examDetailForm.agencyName" style="width: 200px !important" placeholder="请输入" clearable maxlength="50" />
        </el-form-item>
        <el-form-item label="学员姓名：" label-width="70px">
          <el-input class="zwx-input" v-model.trim="examDetailForm.userName" style="width: 200px !important" placeholder="请输入" clearable maxlength="50" />
        </el-form-item>
        <el-form-item label="证件号：" label-width="70px">
          <el-input class="zwx-input" v-model.trim="examDetailForm.idc" style="width: 200px !important" placeholder="请输入" clearable maxlength="50" />
        </el-form-item>
      </div>
      <div>
        <el-form-item label="学员类别：" label-width="70px">
          <el-select v-model="examDetailForm.traineeType" placeholder="请选择" class="zwx-select zwx-select-student" popper-class="zwx-select-popper"  clearable @change="val => traineeTypeChange(val)" style="width: 200px !important">
            <el-option v-for="item in traineeTypeOptions" :key="item.codeNo" :label="item.codeName" :value="item.codeNo"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="培训类型：" label-width="70px">
          <el-select v-model="examDetailForm.trainingType" placeholder="请选择" class="zwx-select zwx-select-student" popper-class="zwx-select-popper"  clearable style="width: 200px !important" @visible-change="visibleChange">
            <el-option v-for="item in trainingTypeChangeOptions" :key="item.codeNo" :label="item.codeName" :value="item.codeNo"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="考试结果：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="examDetailForm.examResultArr">
            <el-checkbox class="zwx-checkbox" :label="1">通过</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="0">不通过</el-checkbox>
          </el-checkbox-group>
        </el-form-item> -->
        <el-form-item label="审核状态：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="examDetailForm.approveStatusArr">
            <el-checkbox class="zwx-checkbox" :label="0">待审核</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="1">审核通过</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="2">审核不通过</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="3">发证失败</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
      <!-- <div>
        <el-form-item label="审核状态：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="examDetailForm.approveStatusArr">
            <el-checkbox class="zwx-checkbox" :label="0">待审核</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="1">审核通过</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="2">审核不通过</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="3">发证失败</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div> -->
    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="getStudentExamDetail(1)">查询</el-button>
      <el-button class="zwx-button zwx-button-28" @click="approve()">批量通过</el-button>
    </div>

    <!-- 列表展示部分 -->
    <el-table class="zwx-table" row-key="rid" :data="traineeExamExpList" border stripe tooltip-effect="light" @selection-change="listSelectionChange">
      <el-table-column key="selection" type="selection" width="55" header-align="center" align="center" :selectable="selectable"></el-table-column>
      <el-table-column prop="agencyName" label="培训机构" min-width="160" header-align="center" align="left"></el-table-column>
      <el-table-column prop="userName" label="姓名" width="150" header-align="center" align="center"></el-table-column>
      <el-table-column prop="cardTypeName" label="证件类型" width="150" header-align="center" align="center"></el-table-column>
      <el-table-column prop="idc" label="证件号" width="220" header-align="center" align="center"></el-table-column>
      <el-table-column prop="traineeTypeName" label="学员类别" width="160" header-align="center" align="center"></el-table-column>
      <el-table-column prop="trainingTypeName" label="培训类型" width="90" header-align="center" align="center"></el-table-column>
      <el-table-column prop="examTime" label="考试完成时间" width="180" header-align="center" align="center"></el-table-column>
      <el-table-column prop="examScore" label="考试分数" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          {{ toNumber(scope.row.examScore) }}
        </template>
      </el-table-column>
      <el-table-column prop="examResult" label="考试结果" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          <span class="zwx-circle-text zwx-circle-text:before" :class="getExamResultClass(scope.row.examResult)">{{ scope.row.examResult ? '通过' : '不通过' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="approveStatus" label="审核状态" width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <span class="zwx-circle-text zwx-circle-text:before" :class="getClass(scope.row.approveStatus)">{{ approveStatusChange(scope.row.approveStatus) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" header-align="left" align="left" fixed="right" :min-width="180">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="viewExamResultDetail(scope.row)">查看答卷</el-button>
          <el-button v-if="scope.row.approveStatus === 0 && scope.row.examResult " class="zwx-button zwx-button-text-26" type="text" @click="approve(scope.row)">通过</el-button>
          <el-button v-if="scope.row.approveStatus === 0 && scope.row.examResult " class="zwx-button zwx-button-text-26" type="danger" @click="openDialog(scope.row)">驳回</el-button>
          <el-button v-if="scope.row.approveStatus === 3" class="zwx-button zwx-button-text-26" type="text" @click="regeneration(scope.row)">重新生成证书</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器部分 -->
    <base-pagination :parentPage="examDetailForm.currentPage" :pageSize="examDetailForm.pageSize" :total="total" @currentChange="getStudentExamDetail" />

    <!-- 驳回弹框 -->
    <base-dialog ref="replyDialogRef" class="reply-dialog" @determine="replyDialogSubmit" title="驳回" @cancel="replyDialogCancel" @close="replyDialogClose">
      <div style="margin-bottom:10px">
        <el-form ref="failForm" class="zwx-form" :model="failForm" :rules="rules">
          <el-form-item label-width="82px" label="驳回原因：" prop="failMsg">
            <el-input class="zwx-textarea" type="textarea" v-model.trim="failForm.failMsg" style="width: 500px !important;" :autosize="{ minRows: 5, maxRows: 5 }" resize="none" maxlength="100" placeholder="请输入" clearable />
          </el-form-item>
        </el-form>
      </div>
    </base-dialog>
  </div>
</template>

<script>
export default {
  name: 'StudentExamDetails',
  components: {},
  props: {},
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
      examDetailForm: {
        agencyName: '', //培训机构
        userName: '', //学员姓名
        idc: '',
        traineeType: '', //学员类型
        trainingType: '', //培训类型
        examResultArr: [], //考试结果
        approveStatusArr: [], //审核状态
        currentPage: 1,
        pageSize: 18,
      },
      total: 0,
      traineeTypeOptions: [],
      trainingTypeOptions: [],
      trainingTypeChangeOptions: [],
      traineeExamExpList: [],
      uuidList: [],
      failForm: {
        failMsg: '',
      },
      rules: {
        failMsg: [{ required: true, message: '请输入驳回原因', trigger: ['change', 'blur'] }],
      },
      curItem: {},
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {
    this.$simpleCodeTools.getSimpleCodeListByCodeTypeCodes('5002', data => {
      this.traineeTypeOptions = this.manyListFul(data['5002'], '')
      this.trainingTypeOptions = data['5002']
      this.getStudentExamDetail(1)
    })
  },
  methods: {
    //培训类型激活前判断是否先选择了学员类别
    //flag为true /false  下拉框出现/隐藏时触发	出现则为 true，隐藏则为 false
    visibleChange(flag) {
      if (flag && !this.examDetailForm.traineeType) {
        this.$notify.info({ title: '提示', message: '请先选择学员类别' })
      }
    },
    selectable(row) {
      // 禁用某些行
      return row.approveStatus === 0
    },

    //审核状态
    approveStatusChange(val) {
      let approveName = ''
      switch (val) {
        case 0:
          approveName = '待审核'
          break
        case 1:
          approveName = '审核通过'
          break
        case 2:
          approveName = '审核不通过'
          break
        case 3:
          approveName = '发证失败'
          break

        default:
          break
      }
      return approveName
    },
    getClass(status) {
      let val = ''
      switch (status) {
        case 0:
          val = 'zwx-circle-underway zwx-circle-underway:before'
          break
        case 1:
          val = 'zwx-circle-complete zwx-circle-complete:before'
          break
        case 2:
          val = 'zwx-circle-finish zwx-circle-finish::before'
          break
        case 3:
          val = 'zwx-circle-finish zwx-circle-finish:before'
          break
        default:
          break
      }
      return val
    },
    getExamResultClass(status) {
      let val = ''
      switch (status) {
        case true:
          val = 'zwx-circle-complete zwx-circle-complete:before'
          break
        case false:
          val = 'zwx-circle-finish zwx-circle-finish::before'
          break
        default:
          break
      }
      return val
    },
    toNumber(val) {
      return parseFloat(val).toFixed(0)
    },
    //列表多选
    listSelectionChange(selectList) {
      this.uuidList = selectList.map(item => {
        return item.uuid
      })
    },
    traineeTypeChange(data) {
      this.examDetailForm.trainingType = ''
      if (data) {
        this.trainingTypeChangeOptions = this.manyListFul(this.trainingTypeOptions, data)
      } else {
        this.trainingTypeChangeOptions = []
      }
    },
    manyListFul(data, pid) {
      //递归方法 生成 json tree 数据
      var result = [],
        temp,
        obj
      data.some(e => {
        let LevelNoArr = e.codeLevelNo.split('.')
        if ((e.codeLevelNo == e.codeNo && pid == '') || LevelNoArr[LevelNoArr.length - 2] == pid) {
          obj = { ...e }
          temp = this.manyListFul(data, e.codeNo)
          if (temp.length > 0) {
            obj.children = temp
          }
          result.push(obj)
        }
      })
      return result
    },
    getStudentExamDetail(currentPage) {
      this.examDetailForm.currentPage = currentPage
      let data = {
        ...this.examDetailForm,
      }
      data.idc = this.$zwxSm.sm4JsEncrypt(this.examDetailForm.idc)
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/training/platform/getAuditExamResultList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.traineeExamExpList = data.resultList
            this.examDetailForm.pageSize = data.pageSize
            this.total = data.resultCount
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.error({ title: '错误', message: data.mess })
        }
      )
    },

    //查看答卷
    viewExamResultDetail(item) {
      this.$router.push({ name: 'ExamResultDetails', params: { uuid: item.examRecordUid } })
    },
    // 点击驳回
    openDialog(item) {
      this.$refs.replyDialogRef.show(true)
      this.failForm = {
        failMsg: '',
      }
      this.curItem = item
    },
    /**
     * 弹框取消
     */
    replyDialogCancel() {
      this.$refs.replyDialogRef.show(false)
    },
    /**
     * 弹框关闭
     */
    replyDialogClose() {
      // 清空表单
      this.failForm = {
        failMsg: '',
      }
      this.curItem = {}
      this.$refs.failForm.resetFields()
    },
    /**
     * 弹框确定
     */
    replyDialogSubmit() {
      this.$refs.failForm.validate(valid => {
        if (valid) {
          this.reject(this.curItem)
        }
      })
    },
    //审核
    approve(item) {
      if (!item && this.uuidList.length == 0) {
        this.$system.notify('提示', '请先选择数据', 'info')
        return
      }
      this.$system.msgbox('', '提示', '是否确认通过？', '确定', '取消',
        () => {
          let data = {
            uuidList: item ? [item.uuid] : this.uuidList,
          }
          this.$emit('loading', true)
          this.$system.postJson(
            this.api + '/training/platform/approveExams-1',
            data,
            true,
            true,
            data => {
              this.$emit('loading', false)
              if (data.type === '00') {
                this.$system.notify('成功', data.mess, 'success')
                this.uuidList = null
                this.getStudentExamDetail(1)
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
              } else {
                this.$system.notify('错误', data.mess, 'error')
              }
            },
            () => {
              this.$emit('loading', false)
              this.$system.error({ title: '错误', message: data.mess })
            }
          )
        },
        () => { }
      )

    },
    //驳回
    reject(item) {
      let data = {
        failMsg: this.failForm.failMsg,
        uuidList: [item.uuid],
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/training/platform/unApproveExams-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.$refs.replyDialogRef.show(false)
            this.getStudentExamDetail(1)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.error({ title: '错误', message: data.mess })
        }
      )
    },
    //重新生成证书
    regeneration(item) {
      this.$system.msgbox('', '提示', '是否确认重新生成证书？', '确定', '取消',
        () => {
          let data = {
            uuid: item.uuid,
          }
          this.$emit('loading', true)
          this.$system.postJson(
            this.api + '/training/platform/approveExams-1',
            data,
            true,
            true,
            data => {
              this.$emit('loading', false)
              if (data.type === '00') {
                this.$system.notify('成功', data.mess, 'success')
                this.getStudentExamDetail(1)
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
              } else {
                this.$system.notify('错误', data.mess, 'error')
              }
            },
            () => {
              this.$emit('loading', false)
              this.$system.error({ title: '错误', message: data.mess })
            }
          )
        },
        () => { }
      )
    },
  },
}
</script>

<style scoped lang="less">
.student-exam-details {
  .zwx-select-student /deep/.el-input__inner {
    width: 200px !important;
  }

  /deep/ .reply-dialog .el-dialog {
    width: 636px !important;
    min-width: 636px !important;
  }
  /deep/ .reply-dialog .el-dialog__body {
    max-height: 300px !important;
  }
  .reply-dialog .el-dialog__footer {
    border-top: unset !important;
  }

  /deep/.reply-dialog .el-radio.zwx-radio-course > .el-radio__input + .el-radio__label {
    display: none;
  }
}
</style>
