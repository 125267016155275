var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "student-exam-details" },
    [
      _c(
        "el-form",
        {
          key: "examDetailForm",
          ref: "examDetailForm",
          staticClass: "zwx-form",
          attrs: { model: _vm.examDetailForm, "label-position": "right" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form-item",
                { attrs: { label: "培训机构：", "label-width": "70px" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "50"
                    },
                    model: {
                      value: _vm.examDetailForm.agencyName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.examDetailForm,
                          "agencyName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "examDetailForm.agencyName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "学员姓名：", "label-width": "70px" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "50"
                    },
                    model: {
                      value: _vm.examDetailForm.userName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.examDetailForm,
                          "userName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "examDetailForm.userName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "证件号：", "label-width": "70px" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "50"
                    },
                    model: {
                      value: _vm.examDetailForm.idc,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.examDetailForm,
                          "idc",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "examDetailForm.idc"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                { attrs: { label: "学员类别：", "label-width": "70px" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "zwx-select zwx-select-student",
                      staticStyle: { width: "200px !important" },
                      attrs: {
                        placeholder: "请选择",
                        "popper-class": "zwx-select-popper",
                        clearable: ""
                      },
                      on: { change: val => _vm.traineeTypeChange(val) },
                      model: {
                        value: _vm.examDetailForm.traineeType,
                        callback: function($$v) {
                          _vm.$set(_vm.examDetailForm, "traineeType", $$v)
                        },
                        expression: "examDetailForm.traineeType"
                      }
                    },
                    _vm._l(_vm.traineeTypeOptions, function(item) {
                      return _c("el-option", {
                        key: item.codeNo,
                        attrs: { label: item.codeName, value: item.codeNo }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "培训类型：", "label-width": "70px" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "zwx-select zwx-select-student",
                      staticStyle: { width: "200px !important" },
                      attrs: {
                        placeholder: "请选择",
                        "popper-class": "zwx-select-popper",
                        clearable: ""
                      },
                      on: { "visible-change": _vm.visibleChange },
                      model: {
                        value: _vm.examDetailForm.trainingType,
                        callback: function($$v) {
                          _vm.$set(_vm.examDetailForm, "trainingType", $$v)
                        },
                        expression: "examDetailForm.trainingType"
                      }
                    },
                    _vm._l(_vm.trainingTypeChangeOptions, function(item) {
                      return _c("el-option", {
                        key: item.codeNo,
                        attrs: { label: item.codeName, value: item.codeNo }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "审核状态：", "label-width": "70px" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.examDetailForm.approveStatusArr,
                        callback: function($$v) {
                          _vm.$set(_vm.examDetailForm, "approveStatusArr", $$v)
                        },
                        expression: "examDetailForm.approveStatusArr"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 0 } },
                        [_vm._v("待审核")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 1 } },
                        [_vm._v("审核通过")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 2 } },
                        [_vm._v("审核不通过")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 3 } },
                        [_vm._v("发证失败")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "independent-button-row" },
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-icontext-28",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function($event) {
                  return _vm.getStudentExamDetail(1)
                }
              }
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-28",
              on: {
                click: function($event) {
                  return _vm.approve()
                }
              }
            },
            [_vm._v("批量通过")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "zwx-table",
          attrs: {
            "row-key": "rid",
            data: _vm.traineeExamExpList,
            border: "",
            stripe: "",
            "tooltip-effect": "light"
          },
          on: { "selection-change": _vm.listSelectionChange }
        },
        [
          _c("el-table-column", {
            key: "selection",
            attrs: {
              type: "selection",
              width: "55",
              "header-align": "center",
              align: "center",
              selectable: _vm.selectable
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "agencyName",
              label: "培训机构",
              "min-width": "160",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "userName",
              label: "姓名",
              width: "150",
              "header-align": "center",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cardTypeName",
              label: "证件类型",
              width: "150",
              "header-align": "center",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "idc",
              label: "证件号",
              width: "220",
              "header-align": "center",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "traineeTypeName",
              label: "学员类别",
              width: "160",
              "header-align": "center",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "trainingTypeName",
              label: "培训类型",
              width: "90",
              "header-align": "center",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "examTime",
              label: "考试完成时间",
              width: "180",
              "header-align": "center",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "examScore",
              label: "考试分数",
              width: "90",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.toNumber(scope.row.examScore)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "examResult",
              label: "考试结果",
              width: "90",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "zwx-circle-text zwx-circle-text:before",
                        class: _vm.getExamResultClass(scope.row.examResult)
                      },
                      [_vm._v(_vm._s(scope.row.examResult ? "通过" : "不通过"))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "approveStatus",
              label: "审核状态",
              width: "120",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "zwx-circle-text zwx-circle-text:before",
                        class: _vm.getClass(scope.row.approveStatus)
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.approveStatusChange(scope.row.approveStatus)
                          )
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "header-align": "left",
              align: "left",
              fixed: "right",
              "min-width": 180
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-text-26",
                        attrs: { type: "text", icon: "el-icon-arrow-right" },
                        on: {
                          click: function($event) {
                            return _vm.viewExamResultDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("查看答卷")]
                    ),
                    scope.row.approveStatus === 0 && scope.row.examResult
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.approve(scope.row)
                              }
                            }
                          },
                          [_vm._v("通过")]
                        )
                      : _vm._e(),
                    scope.row.approveStatus === 0 && scope.row.examResult
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.openDialog(scope.row)
                              }
                            }
                          },
                          [_vm._v("驳回")]
                        )
                      : _vm._e(),
                    scope.row.approveStatus === 3
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.regeneration(scope.row)
                              }
                            }
                          },
                          [_vm._v("重新生成证书")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("base-pagination", {
        attrs: {
          parentPage: _vm.examDetailForm.currentPage,
          pageSize: _vm.examDetailForm.pageSize,
          total: _vm.total
        },
        on: { currentChange: _vm.getStudentExamDetail }
      }),
      _c(
        "base-dialog",
        {
          ref: "replyDialogRef",
          staticClass: "reply-dialog",
          attrs: { title: "驳回" },
          on: {
            determine: _vm.replyDialogSubmit,
            cancel: _vm.replyDialogCancel,
            close: _vm.replyDialogClose
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "el-form",
                {
                  ref: "failForm",
                  staticClass: "zwx-form",
                  attrs: { model: _vm.failForm, rules: _vm.rules }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "82px",
                        label: "驳回原因：",
                        prop: "failMsg"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-textarea",
                        staticStyle: { width: "500px !important" },
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 5, maxRows: 5 },
                          resize: "none",
                          maxlength: "100",
                          placeholder: "请输入",
                          clearable: ""
                        },
                        model: {
                          value: _vm.failForm.failMsg,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.failForm,
                              "failMsg",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "failForm.failMsg"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }