var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pe-record-management" },
    [
      _c(
        "el-form",
        {
          key: "peRecordListForm",
          ref: "peRecordListForm",
          staticClass: "zwx-form",
          attrs: { "label-position": "right", model: _vm.peRecordListForm },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "condition-row" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "行政区划：", "label-width": "70px" } },
                [
                  _c("zwx-select-area", {
                    ref: "areaIdArea",
                    staticClass: "cascader-area-biz-zone",
                    attrs: {
                      zoneCode12From: _vm.zoneCode12From,
                      zoneTypeMax: "0",
                      zoneTypeMin: "4",
                      showFullName: false,
                      disabled: false,
                      clearable: true
                    },
                    on: {
                      loadOver: function($event) {
                        return _vm.areaIdLoadOver()
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "单位名称：", "label-width": "70px" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "20",
                      clearable: ""
                    },
                    model: {
                      value: _vm.peRecordListForm.employerNameLike,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.peRecordListForm,
                          "employerNameLike",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "peRecordListForm.employerNameLike"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "员工姓名：", "label-width": "70px" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px!important" },
                    attrs: { placeholder: "请输入", clearable: "" },
                    model: {
                      value: _vm.peRecordListForm.staffNameLike,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.peRecordListForm,
                          "staffNameLike",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "peRecordListForm.staffNameLike"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "身份证号：", "label-width": "70px" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px!important" },
                    attrs: { placeholder: "请输入完整身份证号", clearable: "" },
                    model: {
                      value: _vm.peRecordListForm.cardNo,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.peRecordListForm,
                          "cardNo",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "peRecordListForm.cardNo"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "condition-row", staticStyle: { display: "flex" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "体检机构：", "label-width": "70px" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px!important" },
                    attrs: { placeholder: "请输入", clearable: true },
                    model: {
                      value: _vm.peRecordListForm.organNameLike,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.peRecordListForm,
                          "organNameLike",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "peRecordListForm.organNameLike"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "体检时间：", "label-width": "70px" } },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "198px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.$validate.noBigDate(
                        _vm.$data["peRecordListForm"],
                        "peEndDate",
                        true
                      ),
                      placeholder: "开始日期"
                    },
                    model: {
                      value: _vm.peRecordListForm.peBeginDate,
                      callback: function($$v) {
                        _vm.$set(_vm.peRecordListForm, "peBeginDate", $$v)
                      },
                      expression: "peRecordListForm.peBeginDate"
                    }
                  }),
                  _vm._v(" - "),
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "198px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.$validate.noSmallDate(
                        _vm.$data["peRecordListForm"],
                        "peBeginDate",
                        true
                      ),
                      placeholder: "结束日期"
                    },
                    model: {
                      value: _vm.peRecordListForm.peEndDate,
                      callback: function($$v) {
                        _vm.$set(_vm.peRecordListForm, "peEndDate", $$v)
                      },
                      expression: "peRecordListForm.peEndDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "condition-row", staticStyle: { display: "flex" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "在岗状态：", "label-width": "70px" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.peRecordListForm.postStateList,
                        callback: function($$v) {
                          _vm.$set(_vm.peRecordListForm, "postStateList", $$v)
                        },
                        expression: "peRecordListForm.postStateList"
                      }
                    },
                    _vm._l(_vm.peRecordListFormExp.postStateOption, function(
                      item
                    ) {
                      return _c(
                        "el-checkbox",
                        {
                          key: item.codeNo,
                          staticClass: "zwx-checkbox",
                          attrs: { label: item.codeNo }
                        },
                        [_vm._v(" " + _vm._s(item.codeName) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "condition-separator" }),
              _c(
                "el-form-item",
                { attrs: { label: "主检结论：", "label-width": "70px" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.peRecordListForm.conclusionCodeList,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.peRecordListForm,
                            "conclusionCodeList",
                            $$v
                          )
                        },
                        expression: "peRecordListForm.conclusionCodeList"
                      }
                    },
                    _vm._l(
                      _vm.peRecordListFormExp.mainInspectionConclusionOption,
                      function(item) {
                        return _c(
                          "el-checkbox",
                          {
                            key: item.codeNo,
                            staticClass: "zwx-checkbox",
                            attrs: { label: item.codeNo }
                          },
                          [_vm._v(" " + _vm._s(item.codeName) + " ")]
                        )
                      }
                    ),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "condition-row", staticStyle: { display: "flex" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "体检类型：", "label-width": "70px" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.peRecordListForm.peTypeList,
                        callback: function($$v) {
                          _vm.$set(_vm.peRecordListForm, "peTypeList", $$v)
                        },
                        expression: "peRecordListForm.peTypeList"
                      }
                    },
                    _vm._l(_vm.peRecordListFormExp.peTypeOption, function(
                      item
                    ) {
                      return _c(
                        "el-checkbox",
                        {
                          key: item.id,
                          staticClass: "zwx-checkbox",
                          attrs: { label: item.id }
                        },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "condition-separator" }),
              _c(
                "el-form-item",
                { attrs: { "label-width": "70px", label: "是否建档：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.peRecordListForm.archivesArr,
                        callback: function($$v) {
                          _vm.$set(_vm.peRecordListForm, "archivesArr", $$v)
                        },
                        expression: "peRecordListForm.archivesArr"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 0 } },
                        [_vm._v("单位未建档")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 1 } },
                        [_vm._v("员工未建档")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 2 } },
                        [_vm._v("员工已建档")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "independent-button-row" },
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-icontext-28",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function($event) {
                  return _vm.queryPeRecordList(1)
                }
              }
            },
            [_vm._v("查询")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "peRecordListTable",
          staticClass: "zwx-table sortable-table",
          attrs: {
            "row-key": "rid",
            border: "",
            stripe: "",
            data: _vm.peRecordListFormExp.tableList
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "bizFullName",
              label: "行政区划",
              width: "180",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "organUnitName",
              label: "单位名称",
              width: "240",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "120",
              prop: "staffName",
              label: "姓名",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "60",
              prop: "sexStr",
              label: "性别",
              "header-align": "center",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "140",
              prop: "peCode",
              label: "体检编号",
              "header-align": "center",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "200",
              prop: "cardNo",
              label: "身份证号",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.cardNo) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "230",
              prop: "organName",
              label: "体检机构",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "120",
              prop: "peDate",
              label: "体检时间",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.peDate != undefined &&
                            scope.row.peDate != ""
                            ? scope.row.peDate.substring(0, 10)
                            : scope.row.peDate
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "peType",
              label: "体检类型",
              width: "150",
              "header-align": "center",
              align: "center",
              formatter: _vm.peTypeFormatter
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "postStateStr",
              label: "在岗状态",
              width: "100",
              "header-align": "center",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "主检结论",
              width: "180",
              "header-align": "center",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(
                    scope.row.mainInspectionConclusionList,
                    function(item, index) {
                      return _c(
                        "span",
                        { key: item, style: _vm.mainInspectionColor(item) },
                        [
                          _vm._v(" " + _vm._s(item) + " "),
                          scope.row.mainInspectionConclusionList.length != 1 &&
                          index !=
                            scope.row.mainInspectionConclusionList.length - 1
                            ? _c("i", { staticStyle: { color: "black" } }, [
                                _vm._v("、")
                              ])
                            : _vm._e()
                        ]
                      )
                    }
                  )
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "危害因素",
              "header-align": "left",
              align: "left",
              "min-width": 240
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.hazardName || "--"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "160",
              "header-align": "left",
              align: "left",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-text-26",
                        attrs: { icon: "el-icon-arrow-right", type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.detail(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("base-pagination", {
        staticClass: "normal-pagination",
        attrs: {
          parentPage: _vm.peRecordListForm.currentPage,
          pageSize: _vm.peRecordListFormExp.pageSize,
          total: _vm.peRecordListFormExp.total
        },
        on: { currentChange: _vm.queryPeRecordList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }